.button{
    background-color: #FF7149;
    text-decoration: none;
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: bold;
    font-family: "Exo", sans-serif;
    padding: 16px 48px;
    transition: 600ms;
}
.button:hover{
    background-color: #fb5c2f;
}