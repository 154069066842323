.customer__subtitle,
.customer__title{
    font-family: "Exo", sans-serif;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
}
.customer__subtitle{
    margin: 0;
    font-size: 24px;
    color: #001E4C;
}
.customer__title{
    margin: 6px 0 64px 0;
    font-size: 40px;
    color: #000000;
}
.customer__logos{
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    padding-bottom: 120px;
}
.customer__logos img{
    width: 165px;
    height: 78px;
    opacity: 0.4;
}
.customer__logos img:hover{
    width: 165px;
    height: 78px;
    opacity: 1;
}
@media(max-width:767px){
    .customer__subtitle{
        font-size: 18px;
    }
    .customer__title{
        font-size: 24px;
        margin: 6px 0 32px 0;
    }
    .customer__logos{
        gap: 32px;
        justify-content: center;
        padding-bottom: 64px;
    }

}