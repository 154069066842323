.slide__container{
    text-align: center;
}
.slide__avatar{
    border-radius: 100%;
}
.slide__name,
.slide__position{
    font-family: "Exo", sans-serif;
    font-weight: bold;
}
.slide__name{
    color: #FF7149;
    font-size: 32px;
    margin-bottom: 8px;
    margin-top: 32px;
}
.slide__position{
    color: #656269;
    font-size: 20px;
    margin: 0;
}
.slide__testimonial{
    width: 794px;
    margin: auto;
    font-style: italic;
    color: #656269;
    font-size: 24px;
    margin-top: 32px;
}
.testimonialSwiper > .swiper-button-prev{
    color: #FF7149!important;
}
.testimonialSwiper > .swiper-button-next{
    color: #FF7149!important;
}