.service{
    background-color: #F1F2F4;
}
.service__container{
    padding: 120px 0;
}
.service__subtitle,
.service__title{
    font-family: "Exo", sans-serif;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
}
.service__subtitle{
    margin: 0;
    font-size: 24px;
    color: #001E4C;
}
.service__title{
    margin: 6px 0 64px 0;
    font-size: 40px;
    color: #000000;
}
.service__card{
    display: flex;
    justify-content: space-between;
}
@media(max-width:767px){
    .service__container{
        padding: 64px 0;
    }
    .service__subtitle{
        font-size: 18px;
    }
    .service__title{
        font-size: 24px;
        margin: 6px 0 32px 0;
    }
    .service__card{
        flex-direction: column;
        gap: 16px;
    }
    .card{
        margin: auto;
        background-repeat: no-repeat;
        background-size: cover;
    }
}