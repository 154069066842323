.cta{
    background-repeat: no-repeat;
    background-size: cover;
}
.cta__container{
    padding: 80px 0;
}
.cta__subtitle,
.cta__title{
    font-family: "Exo", sans-serif;
    font-weight: bold;
}
.cta__subtitle{
    font-size: 24px;
    text-transform: uppercase;
    color: #FF7149;
    margin: 0 0 10px 0;
}
.cta__title{
    color: #FFFFFF;
    font-size: 40px;
    width: 589px;
    margin: 0 0 26px 0;
}
.cta__button{
    margin-top: 40px;
}
@media(max-width:767px){
    .cta{
        background-position-x: center;
    }
    .cta__subtitle{
        font-size: 16px;
    }
    .cta__title{
        font-size: 24px;
        width: auto;
    }
}