.testimonial__container{
    padding: 120px 0;
}
.testimonial__subtitle,
.testimonial__title{
    font-family: "Exo", sans-serif;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
}
.testimonial__subtitle{
    margin: 0;
    font-size: 24px;
    color: #001E4C;
}
.testimonial__title{
    margin: 6px 0 64px 0;
    font-size: 40px;
    color: #000000;
}
.testimonial__divisor{
    height: 1px;
    border: none;
    background-color: rgba(0, 0, 0, 0.10);
    margin-top: 64px;
}
@media(max-width:767px){
    .testimonial__container {
        padding: 64px 0;
    }
    .testimonial__subtitle{
        font-size: 18px;
    }
    .testimonial__title{
        font-size: 24px;
        margin: 6px 10px 32px 10px;
    }
    .slide__avatar {
        border-radius: 100%;
        width: 120px;
    }
    .slide__name{
        font-size: 24px;
    }
    .slide__position{
        font-size: 16px;
    }
    .slide__testimonial{
        width: auto;
        font-size: 16px;
        margin: 16px;
    }
    .testimonial__divisor{
        margin: 0;
    }
    .testimonialSwiper > .swiper-button-next{
        top: 100px;
    }
    .testimonialSwiper > .swiper-button-prev{
        top: 100px;
    }
}