
.about__container{
    display: flex;
    gap: 122px;
    padding: 120px 0;
    align-items: center;
}
.about__col-left,
.about__col-right{
    width: 50%;
}
.about__subtitle{
    text-transform: uppercase;
    font-family: "Exo", sans-serif;
    color: #001E4C;
    font-size: 24px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 8px;
}
.about__title{
    text-transform: uppercase;
    font-family: "Exo", sans-serif;
    color: #000000;
    font-size: 40px;
    font-weight: bold;
    margin: 0;
}
.about__content-text{
    margin: 32px 0;
}
.about__paragraph{
    font-size: 18px;
    color: #656269;
    font-family: "Open Sans", sans-serif;    
}
.about__divider{
    height: 1px;
    background-color: #65626943;
    border: none;
    margin: 32px 0;
}
.about__founder{
    display: flex;
    gap: 22px;
    align-items: center;
}
.about__founder-avatar{
    border:2px solid #65626943;
    border-radius: 200px;
}
.about__founder-quote{
    font-size: 18px;
    color: #656269;
    font-family: "Open Sans", sans-serif;  
    font-style: italic;
}
.about__fun-facts{
    display: flex;
    justify-content: space-between;
    padding-bottom: 120px;
}
@media(max-width:767px){
    .about__container{
        padding:64px 0 32px 0;
        flex-direction: column;
        gap: 32px;
        margin: 0px 20px;
        width: auto;
    }
    .about__subtitle{
        font-size: 16px;
    }
    .about__title{
        font-size: 24px;
    }
    .about__paragraph{
        font-size: 16px;
    }
    .about__col-left, .about__col-right{
        width: 100%;
    }
    .about__col-left img{
        width: 100%;
    }
    .about__content-text {
        margin: 16px 0;
    }
    .about__founder{
        flex-direction: column;
        gap: 0;
    }
    .about__founder-avatar{
        width: 33%!important;
    }
    .about__founder-quote{
        font-size: 14px;
        text-align: center;
    }
    .about__fun-facts{
        flex-direction: column;
        gap: 32px;
        padding-bottom: 64px;
    }
    .facts__item-image {
        width: 64px;
        height: 64px;
    }
    .facts__item-number{
        font-size: 24px;
    }
    .facts__item-feature{
        font-size: 20px;
    }
}