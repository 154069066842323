.footer{
    background-color: #001E4C;
}
.footer__text{
    font-size: 14px;
    color: #FFFFFF;
    padding: 32px 0;
    text-align: center;
    margin: 0;
    text-transform: uppercase;
}