.header{
    background-color: #001E4C;
    height: 86px;
    display: flex;
    align-items: center;
    width: 100%;
    position: fixed;
    z-index: 10;
}
.header_mobile{
    display: none!important;
}
.header__container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header__menu{
    display: flex;
    gap: 32px;
    align-items: center;
}
.header__logo img{
    height: 75px;
    width: auto;
}
.header__menu-item{
    text-transform: uppercase;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    font-family: "Exo", sans-serif;
}
.cta{
    color: #FFFFFF;
    background-color: #ff7149;
    padding: 12px 24px;
}
@media (max-width:767px){
    .header_desktop{
        display: none;
    }
    .header_mobile{
        display: block!important;
    }
    .header__logo img {
        height: 64px;
        width: auto;
    }
    .header_mobile_wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}