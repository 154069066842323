.facts__item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.facts__item-image{
    width: 120px;
    height: 120px;
}
.facts__item-number{
    margin: 16px 0 4px 0;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: bold;
    color: #FF7149;
    font-family: "Exo", sans-serif;
}
.facts__item-feature{
    margin: 0;
    font-size: 32px;
    font-weight: bold;
    color: #656269;
    font-family: "Exo", sans-serif;
}
