.responsive__menu{
    background-color: #011e4c;
    display: flex;
    flex-direction: column;
    padding: 24px;
}
.responsive__menu > .header__menu-item{
    margin-bottom: 12px;
}
.responsive__menu > .cta{
    text-align: center;
    margin-top: 12px;
}