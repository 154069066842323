.banner{
    background-image: url('../../../public/img/hero.webp');
    height: 710px;
}
.banner__container{
    display: flex;
    height: 100%;
    flex-direction: row-reverse;
}
.banner__wrapper{
    width: 585px;
    display: flex;
    justify-content: end;
    align-items: center;
}
.banner__box{
    display: flex;
    flex-direction: column;
    max-width: 585px;
    width: 100%;
    background-color: rgba(250, 250, 250, 0.75);
    border-radius: 16px;
    padding: 80px 60px;
}
.banner__title{
    margin: 0;
    font-size: 64px;
    font-weight: 900;
    color: #000000;
    font-family: "Exo", sans-serif;
    text-transform: uppercase;
}
.banner__sutitle{
    margin: 0;
    font-size: 44px;
    font-weight: bold;
    color: #000000;
    font-family: "Exo", sans-serif;
    text-transform: uppercase;
}
.banner__description{
    margin: 20px 0 40px 0;
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    font-family: "Exo", sans-serif;
    max-width: 406px;
    width: 100%;
}
@media(max-width:767px){
    .banner{
        height: 485px;
        background-size: cover;
        background-position-x: center;
    }
    .banner__wrapper{
        width: 100%;
    }
    .banner__box{
        max-width: 100%;
    }
    .banner__title{
        font-size: 32px;
    }
    .banner__sutitle{
        font-size: 20px;
    }
    .banner__description{
        font-size: 16px;
    }
    .banner__box{
        padding: 32px 20px;
        margin: 12px;
    }
}

