.card{
    width: 285px;
    height: 402px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    position: relative;
}
.card__title{
    display: flex;
    height: 100%;
    font-family: "Exo", sans-serif;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: bold;
    padding: 0 36px 0 24px;
    align-items: end;
    margin: 0;
    position: relative;
    bottom: 32px;
}

.card__hovered{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: self-start;
    height: 100%; /* Certifica-se que o div ocupe toda a altura do card */
    padding: 20px;
    box-sizing: border-box; 
}
.card__title--hovered{
    display: flex;
    width: 100%;
    height: 100%;
    font-family: "Exo", sans-serif;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: bold;
    align-items: end;
    margin: 0;
    position: relative;
    bottom: 32px;
}
.card__description{
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #FFFFFF;
    margin-top: -16px;
    margin-bottom: 32px;
}
.card__button--hovered{
    margin-bottom: 24px;
}
.card__button--hovered > .button{
    font-size: 14px;
    padding: 8px 16px;
}

